.content {
  min-height: calc(100vh - 138px);
}

.patientTabs {
  width: 100%;
}

.overviewContainer {
  margin-top: 40px;
}

.patientInfoContainer {
  width: 100%;
  margin-top: 24px;
}

.adherenceScoreContainer {
  margin-top: 60px;
}

.adherenceMessageContainer {
  margin-top: 30px;
  margin-bottom: 130px;
}

.removePatientButton {
  margin-top: 24px;
}

.packsListItem {
  width: 50%;
  padding: 10px;
}

.packsListItem:nth-child(2n + 1):last-child {
  width: 100%;
}

.packsSkeleton {
  font-size: 20;
  line-height: 2;
}

.adherenceScore {
  width: 80%;
  height: 300px;
  margin: auto;
}

.monthlyTimeRange {
  display: flex;
}
.monthlyTimeRange span {
  margin-top: 3px;
}

.timingsRange {
  display: flex;
  margin-top: 0;
  margin-bottom: 10px;
}

.monthlyTimeRange label,
.timingsRange label {
  padding: 0 5px;
}

.timingsButton {
  margin-top: 20px;
}

.timingsInput {
  width: 76px;
  display: inline-block;
}

.packContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-grey);
  background-color: #fff;
}

.packBody {
  flex: 1 1 auto;
  height: 50%;
}
