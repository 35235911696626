.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #868686;
  background-color: #fff;
  padding: 15px;
}

.none {
  display: none;
}

.packBody {
  flex: 1 1 auto;
  height: 50%;
}

.header {
  text-align: center;
}

.header.active,
.header.prepared {
  color: black;
}

.rowDescriptions {
  width: 16%;
  height: 100%;
  float: left;
  display: flex;
  flex-flow: column;
  text-align: left;
  line-height: 1;
}

.cavity,
.rowDescription {
  height: 10%;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: -1px;
  margin-right: -1px;
  white-space: nowrap;
  text-align: center;
}

.rowButton {
  margin: 5px 5px 0 0;
}

.cavity {
  text-transform: uppercase;
  padding: 0.5rem;
  border: 1px solid;
  border-color: #edf2f6;
}

.cavity button {
  height: 95% !important;
  width: 95% !important;
}

.rowDescription .date {
  font-size: 0.9rem;
}

.dateText {
  font-size: 0.7rem;
  margin: 0.3rem 0;
}

.column {
  width: 20%;
  height: 100%;
  float: left;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.columnHeader .toggleButton {
  width: 80% !important;
}

.columnHeader {
  border: none;
  height: 115px;
  vertical-align: bottom;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.columnHeaderShort {
  height: 40px;
}

.columnHeader img {
  width: 30%;
  height: 25%;
  margin-bottom: 5px;
}

.columnHeader span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 1px 0;
}

.columnFooter {
  height: 30px;
}

.shiftWeekdaysButton {
  align-self: start;
}
