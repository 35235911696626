.card {
  margin-top: 20px;
}
@media only screen and (min-width: 380px) {
  .card {
    max-width: 340px;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.logo {
  width: 100%;
  display: block;
  margin: 0 auto;
}
